import * as React from 'react';
import { useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Container,
  Headline,
  IconModalItem,
  Link,
  List,
  ListItem,
  Paragraph,
  scrollToElement,
} from '../../gov-components';

import airbank from '../../resources/icons/airbank.png';
import ceskaSporitelna from '../../resources/icons/ceskasporitelna.png';
import csobId from '../../resources/icons/csob-id.png';
import komercniBanka from '../../resources/icons/komercnibanka.png';
import moneta from '../../resources/icons/moneta.png';
import fioBanka from '../../resources/icons/fio-banka.png';
import raiffeisenBank from '../../resources/icons/raiffeisenbank.png';
import unicreditBank from '../../resources/icons/unicredit.png';
import creditas from '../../resources/icons/creditas.png';
import mbank from '../../resources/icons/mbank.png';
import dataBox from '../../resources/icons/databox.svg';
import erbGov from '../../resources/icons/erb-logo.svg';
import injection from '../../resources/icons/injection.svg';
import pillsPrescription from '../../resources/icons/pills-prescription.svg';
import portalForRegions from '../../resources/icons/portal-for-regions.svg';
import firstCertificateAuthority from '../../resources/icons/1certificate-authority.png';
import MEPLogo from '../../resources/icons/mobile-key.png';
import mojeIDLogo from '../../resources/icons/mojeID-logo.png';
import NIALogo from '../../resources/icons/NIA-logo.png';
import bankID from '../../resources/icons/bankID.png';
import unionLogo from '../../resources/icons/union-logo.png';
import cssz from '../../resources/icons/cssz-green.svg';
import loginBigArrow from '../../resources/icons/big-arrow.svg';
import arrowUp from '../../resources/icons/arrow-up.svg';
import arrowDown from '../../resources/icons/arrow-down-small.svg';
import portalMyTaxes from '../../resources/icons/taxes-logo.svg';
import map from '../../resources/icons/map-new.svg';
import externalLink from '../../resources/icons/external.svg';

import * as Constants from '../../util/constants';
import { withLocale } from '../../util/routing';

export const IconsWrapperNew = () => {
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(false);

  return (
    <section id={ 'sekce-ikony-cs-en-verze' } className={ 'icon-cards-wrapper-new' }>
      <Container className={ 'gov-container u-bg-color--white container-new' }>
       <Headline type={2} className={ 'gov-badge-tabs__title heading2' }>{t('identityPage.badgeTabs.titleThree')}</Headline>

        <div className={ 'container-new__main-heading' }>
          <Headline type={3} className={ 'container-new__main-title' }>{t("mainPage.sectionOne.mainNewTitle")}</Headline>
        </div>
        <div className={ 'container-new__third-section' } id={ 'icon-wrapper-part' } >
          <div id={ 'icon-wrapper-one' } className={ 'container-new__third-section--part-one u-bg-color--grey' }>
            <Headline type={2} className={ 'container-new__third-section--heading u-mb--24' }>{t("mainPage.sectionTwo.imagePartOneHeading")}</Headline>
            <div>
              {showMore &&
                <Headline type={3}
                          className={ 'container-new__third-section--heading-smaller' }>{t("mainPage.sectionTwo.imagePartOneHeadingSmallOne")}</Headline>
              }
              <div className={ 'container-new__third-section--logos' }>
                <IconModalItem iconSource={ MEPLogo }
                               className={ 'container-new__third-section--logo-icon' }
                               alt={t("mainPage.sectionTwo.imagePartOneHeadingSmallOneItemOneAltTextSecond")}
                               classNameString={ 'container-new__third-section--logo-text' }
                               classNameItem={ 'container-new__third-section--logo-item' }
                               iconText={t("mainPage.sectionTwo.imagePartOneHeadingSmallOneItemOneTextSecond")}
                               modalTitle={t('iconModalOne.titleOne')}
                               modalButtonName={t('iconModalOne.buttonOne')}
                               buttonLink={ 'https://info.identitaobcana.cz/mep/' }
                               modalContent={
                                 <>
                                    <Headline type={3} className={ 'modal-heading' }>{t('iconModalOne.headingOne')}</Headline>
                                    <Paragraph className={'modal-text'}>{t('iconModalOne.textOne')}</Paragraph>
                                    <Headline type={3} className={ 'modal-heading' }>{t('iconModalOne.headingTwo')}</Headline>
                                    <Paragraph className={ 'modal-text' }>
                                      {t('iconModalOne.textTwoPartOne')}&nbsp;
                                      <Link
                                        title={t('iconModalOne.textTwoLinkTitle')}
                                        href={ 'https://info.identitaobcana.cz/mep/' }
                                        target="_blank"
                                        className={ 'stepper-wrapper__text--link' }
                                      >{t('iconModalOne.textTwoLink')}
                                        <img className={ 'plain-list__external-link' }
                                             src={ externalLink }
                                             alt={t("mainPage.imageLinkExternal")} />
                                      </Link>&nbsp;
                                      {t('iconModalOne.textTwoPartTwo')}
                                    </Paragraph>
                                 </>
                               }>
                </IconModalItem>
                <IconModalItem iconSource={ NIALogo }
                               className={ 'container-new__third-section--logo-icon' }
                               alt={t("mainPage.sectionTwo.imagePartOneHeadingSmallOneItemTwoAltText")}
                               classNameString={ 'container-new__third-section--logo-text' }
                               classNameItem={ 'container-new__third-section--logo-item' }
                               iconText={t("mainPage.sectionTwo.imagePartOneHeadingSmallOneItemTwoText")}
                               modalTitle={t('iconModalTwo.titleOne')}
                               modalButtonName={t('iconModalTwo.buttonOne')}
                               buttonLink={ 'https://www.identitaobcana.cz/ProfileRegistration' }
                               modalContent={
                                 <>
                                   <Headline type={3} className={ 'modal-heading' }>{t('iconModalTwo.headingOne')}</Headline>
                                   <Paragraph className={'modal-text'}>{t('iconModalTwo.textOne')}</Paragraph>
                                   <Headline type={3} className={ 'modal-heading' }>{t('iconModalTwo.headingTwo')}</Headline>
                                   <Paragraph className={ 'modal-text' }>
                                     {t('iconModalTwo.textTwoPartOne')}&nbsp;
                                     <Link
                                       title={t('iconModalTwo.textTwoLinkTitle')}
                                       href={ 'https://info.identitaobcana.cz/ups/' }
                                       target="_blank"
                                       className={ 'stepper-wrapper__text--link' }
                                     >{t('iconModalTwo.textTwoLink')}
                                       <img className={ 'plain-list__external-link' }
                                            src={ externalLink }
                                            alt={t("mainPage.imageLinkExternal")} />
                                     </Link>&nbsp;
                                     {t('iconModalTwo.textTwoPartTwo')}
                                   </Paragraph>
                                 </>
                               }>
                </IconModalItem>
                {showMore &&
                  <IconModalItem iconSource={ NIALogo }
                                 className={ 'container-new__third-section--logo-icon' }
                                 alt={t("mainPage.sectionTwo.imagePartOneHeadingSmallOneItemTwoAltText")}
                                 classNameString={ 'container-new__third-section--logo-text' }
                                 classNameItem={ 'container-new__third-section--logo-item no-margin-bottom' }
                                 iconText={t("iconModalThree.textOneItem")}
                                 modalTitle={t('iconModalThree.titleOne')}
                                 modalButtonName={t('iconModalThree.buttonOne')}
                                 buttonLink={ 'https://info.identitaobcana.cz/eop/' }
                                 modalContent={
                                   <>
                                     <Headline type={3}
                                               className={ 'modal-heading' }>{t('iconModalThree.headingOne')}</Headline>
                                     <Paragraph className={ 'modal-text' }>{t('iconModalThree.textOne')}</Paragraph>
                                     <Headline type={3}
                                               className={ 'modal-heading' }>{t('iconModalThree.headingTwo')}</Headline>
                                     <Paragraph className={ 'modal-text' }>
                                       {t('iconModalThree.textTwoPartOne')}&nbsp;
                                       <Link
                                         title={t('iconModalThree.textTwoLinkTitle')}
                                         href={ 'https://info.identitaobcana.cz/ups/' }
                                         target="_blank"
                                         className={ 'stepper-wrapper__text--link' }
                                       >{t('iconModalThree.textTwoLink')}
                                         <img className={ 'plain-list__external-link' }
                                              src={ externalLink }
                                              alt={t("mainPage.imageLinkExternal")} />
                                       </Link>&nbsp;
                                       {t('iconModalThree.textTwoPartTwo')}
                                     </Paragraph>
                                   </>
                                 }>
                  </IconModalItem>
                }
                {!showMore &&
                  <IconModalItem iconSource={ bankID }
                                 className={ 'container-new__third-section--logo-icon' }
                                 alt={t("mainPage.sectionTwo.imagePartOneHeadingSmallOneItemThreeNewAltText")}
                                 classNameString={ 'container-new__third-section--logo-text' }
                                 classNameItem={ 'container-new__third-section--logo-item' }
                                 iconText={t("mainPage.sectionTwo.imagePartOneHeadingSmallOneItemNewThreeText")}
                                 modalTitle={t('iconModalFour.titleOne')}
                                 modalButtonName={t('iconModalFour.buttonOne')}
                                 buttonLink={ 'https://www.bankid.cz/' }
                                 modalContent={
                                   <>
                                     <Headline type={3} className={ 'modal-heading' }>{t('iconModalFour.headingOne')}</Headline>
                                     <Paragraph className={'modal-text'}>{t('iconModalFour.textOne')}</Paragraph>
                                     <Headline type={3} className={ 'modal-heading' }>{t('iconModalFour.headingTwo')}</Headline>
                                     <List plain={true} inline={true} className={ 'list-plain' } >
                                       <ListItem>
                                         <img src={ airbank }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltOne")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ ceskaSporitelna }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltTwo")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ csobId }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltThree")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ fioBanka }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltSeven")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ komercniBanka }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltFour")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ moneta }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltFive")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ raiffeisenBank }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltSix")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ unicreditBank }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltEight")} />
                                       </ListItem>
                                       <ListItem>
                                          <img src={ creditas }
                                               alt={t("mainPage.sectionThree.itemOne.imageTextAltNine")}/>
                                        </ListItem>
                                        <ListItem>
                                          <img src={ mbank }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltTen")}/>
                                        </ListItem>
                                     </List>
                                     <Headline type={3} className={ 'modal-heading' }>{t('iconModalFour.headingThree')}</Headline>
                                     <Paragraph className={ 'modal-text' }>
                                       {t('iconModalFour.textTwoPartOne')}&nbsp;
                                       <Link
                                         title={t('iconModalFour.textTwoLinkTitle')}
                                         href={ 'https://www.bankid.cz/' }
                                         target="_blank"
                                         className={ 'stepper-wrapper__text--link' }
                                       >{t('iconModalFour.textTwoLink')}
                                         <img className={ 'plain-list__external-link' }
                                              src={ externalLink }
                                              alt={t("mainPage.imageLinkExternal")} />
                                       </Link>&nbsp;
                                       {t('iconModalFour.textTwoPartTwo')}
                                     </Paragraph>
                                   </>
                                 }>
                  </IconModalItem>
                }
              </div>
            </div>
            {showMore &&
              <div>
                <Headline type={3}
                          className={ 'container-new__third-section--heading-smaller u-mt--24' }>{t("mainPage.sectionTwo.imagePartOneHeadingSmallTwo")}</Headline>
                <div className={ 'container-new__third-section--logos bank-logos' }>
                  <IconModalItem iconSource={ airbank }
                                 className={ 'container-new__third-section--logo-icon' }
                                 alt={t("mainPage.sectionTwo.imagePartOneHeadingSmallTwoItemFourAltText")}
                                 modalTitle={t('iconModalFour.titleOne')}
                                 modalButtonName={t('iconModalFour.buttonOne')}
                                 buttonLink={ 'https://www.bankid.cz/' }
                                 modalContent={
                                   <>
                                     <Headline type={3}
                                               className={ 'modal-heading' }>{t('iconModalFour.headingOne')}</Headline>
                                     <Paragraph className={ 'modal-text' }>{t('iconModalFour.textOne')}</Paragraph>
                                     <Headline type={3}
                                               className={ 'modal-heading' }>{t('iconModalFour.headingTwo')}</Headline>
                                     <List plain={true} inline={true} className={ 'list-plain' } >
                                       <ListItem>
                                         <img src={ airbank }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltOne")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ ceskaSporitelna }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltTwo")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ csobId }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltThree")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ fioBanka }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltSeven")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ komercniBanka }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltFour")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ moneta }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltFive")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ raiffeisenBank }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltSix")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ unicreditBank }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltEight")} />
                                       </ListItem>
                                       <ListItem>
                                          <img src={ creditas }
                                               alt={t("mainPage.sectionThree.itemOne.imageTextAltNine")}/>
                                        </ListItem>
                                        <ListItem>
                                          <img src={ mbank }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltTen")}/>
                                        </ListItem>
                                     </List>
                                     <Headline type={3}
                                               className={ 'modal-heading' }>{t('iconModalFour.headingThree')}</Headline>
                                     <Paragraph className={ 'modal-text' }>
                                       {t('iconModalFour.textTwoPartOne')}&nbsp;
                                       <Link
                                         title={t('iconModalFour.textTwoLinkTitle')}
                                         href={ 'https://www.bankid.cz/' }
                                         target="_blank"
                                         className={ 'stepper-wrapper__text--link' }
                                       >{t('iconModalFour.textTwoLink')}
                                         <img className={ 'plain-list__external-link' }
                                              src={ externalLink }
                                              alt={t("mainPage.imageLinkExternal")} />
                                       </Link>&nbsp;
                                       {t('iconModalFour.textTwoPartTwo')}
                                     </Paragraph>
                                   </>
                                 }>
                  </IconModalItem>
                  <IconModalItem iconSource={ ceskaSporitelna }
                                 className={ 'container-new__third-section--logo-icon' }
                                 alt={t("mainPage.sectionTwo.imagePartOneHeadingSmallTwoItemOneAltText")}
                                 modalTitle={t('iconModalFour.titleOne')}
                                 modalButtonName={t('iconModalFour.buttonOne')}
                                 buttonLink={ 'https://www.bankid.cz/' }
                                 modalContent={
                                   <>
                                     <Headline type={3}
                                               className={ 'modal-heading' }>{t('iconModalFour.headingOne')}</Headline>
                                     <Paragraph className={ 'modal-text' }>{t('iconModalFour.textOne')}</Paragraph>
                                     <Headline type={3}
                                               className={ 'modal-heading' }>{t('iconModalFour.headingTwo')}</Headline>
                                     <List plain={true} inline={true} className={ 'list-plain' } >
                                       <ListItem>
                                         <img src={ airbank }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltOne")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ ceskaSporitelna }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltTwo")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ csobId }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltThree")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ fioBanka }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltSeven")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ komercniBanka }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltFour")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ moneta }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltFive")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ raiffeisenBank }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltSix")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ unicreditBank }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltEight")} />
                                       </ListItem>
                                       <ListItem>
                                          <img src={ creditas }
                                               alt={t("mainPage.sectionThree.itemOne.imageTextAltNine")}/>
                                      </ListItem>
                                      <ListItem>
                                        <img src={ mbank }
                                             alt={t("mainPage.sectionThree.itemOne.imageTextAltTen")}/>
                                      </ListItem>
                                     </List>
                                     <Headline type={3}
                                               className={ 'modal-heading' }>{t('iconModalFour.headingThree')}</Headline>
                                     <Paragraph className={ 'modal-text' }>
                                       {t('iconModalFour.textTwoPartOne')}&nbsp;
                                       <Link
                                         title={t('iconModalFour.textTwoLinkTitle')}
                                         href={ 'https://www.bankid.cz/' }
                                         target="_blank"
                                         className={ 'stepper-wrapper__text--link' }
                                       >{t('iconModalFour.textTwoLink')}
                                         <img className={ 'plain-list__external-link' }
                                              src={ externalLink }
                                              alt={t("mainPage.imageLinkExternal")} />
                                       </Link>&nbsp;
                                       {t('iconModalFour.textTwoPartTwo')}
                                     </Paragraph>
                                   </>
                                 }>
                  </IconModalItem>
                  <IconModalItem iconSource={ csobId }
                                 className={ 'container-new__third-section--logo-icon' }
                                 alt={t("mainPage.sectionTwo.imagePartOneHeadingSmallTwoItemTwoAltText")}
                                 modalTitle={t('iconModalFour.titleOne')}
                                 modalButtonName={t('iconModalFour.buttonOne')}
                                 buttonLink={ 'https://www.bankid.cz/' }
                                 modalContent={
                                   <>
                                     <Headline type={3}
                                               className={ 'modal-heading' }>{t('iconModalFour.headingOne')}</Headline>
                                     <Paragraph className={ 'modal-text' }>{t('iconModalFour.textOne')}</Paragraph>
                                     <Headline type={3}
                                               className={ 'modal-heading' }>{t('iconModalFour.headingTwo')}</Headline>
                                     <List plain={true} inline={true} className={ 'list-plain' } >
                                       <ListItem>
                                         <img src={ airbank }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltOne")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ ceskaSporitelna }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltTwo")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ csobId }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltThree")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ fioBanka }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltSeven")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ komercniBanka }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltFour")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ moneta }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltFive")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ raiffeisenBank }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltSix")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ unicreditBank }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltEight")} />
                                       </ListItem>
                                       <ListItem>
                                          <img src={ creditas }
                                               alt={t("mainPage.sectionThree.itemOne.imageTextAltNine")}/>
                                      </ListItem>
                                      <ListItem>
                                        <img src={ mbank }
                                             alt={t("mainPage.sectionThree.itemOne.imageTextAltTen")}/>
                                      </ListItem>
                                     </List>
                                     <Headline type={3}
                                               className={ 'modal-heading' }>{t('iconModalFour.headingThree')}</Headline>
                                     <Paragraph className={ 'modal-text' }>
                                       {t('iconModalFour.textTwoPartOne')}&nbsp;
                                       <Link
                                         title={t('iconModalFour.textTwoLinkTitle')}
                                         href={ 'https://www.bankid.cz/' }
                                         target="_blank"
                                         className={ 'stepper-wrapper__text--link' }
                                       >{t('iconModalFour.textTwoLink')}
                                         <img className={ 'plain-list__external-link' }
                                              src={ externalLink }
                                              alt={t("mainPage.imageLinkExternal")} />
                                       </Link>&nbsp;
                                       {t('iconModalFour.textTwoPartTwo')}
                                     </Paragraph>
                                   </>
                                 }>
                  </IconModalItem>
                  <IconModalItem iconSource={ fioBanka }
                                 className={ 'container-new__third-section--logo-icon' }
                                 alt={t("mainPage.sectionTwo.imagePartOneHeadingSmallTwoItemSevenAltText")}
                                 modalTitle={t('iconModalFour.titleOne')}
                                 modalButtonName={t('iconModalFour.buttonOne')}
                                 buttonLink={ 'https://www.bankid.cz/' }
                                 modalContent={
                                   <>
                                     <Headline type={3}
                                               className={ 'modal-heading' }>{t('iconModalFour.headingOne')}</Headline>
                                     <Paragraph className={ 'modal-text' }>{t('iconModalFour.textOne')}</Paragraph>
                                     <Headline type={3}
                                               className={ 'modal-heading' }>{t('iconModalFour.headingTwo')}</Headline>
                                     <List plain={true} inline={true} className={ 'list-plain' } >
                                       <ListItem>
                                         <img src={ airbank }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltOne")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ ceskaSporitelna }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltTwo")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ csobId }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltThree")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ fioBanka }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltSeven")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ komercniBanka }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltFour")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ moneta }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltFive")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ raiffeisenBank }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltSix")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ unicreditBank }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltEight")} />
                                       </ListItem>
                                       <ListItem>
                                          <img src={ creditas }
                                               alt={t("mainPage.sectionThree.itemOne.imageTextAltNine")}/>
                                      </ListItem>
                                      <ListItem>
                                        <img src={ mbank }
                                             alt={t("mainPage.sectionThree.itemOne.imageTextAltTen")}/>
                                      </ListItem>
                                     </List>
                                     <Headline type={3}
                                               className={ 'modal-heading' }>{t('iconModalFour.headingThree')}</Headline>
                                     <Paragraph className={ 'modal-text' }>
                                       {t('iconModalFour.textTwoPartOne')}&nbsp;
                                       <Link
                                         title={t('iconModalFour.textTwoLinkTitle')}
                                         href={ 'https://www.bankid.cz/' }
                                         target="_blank"
                                         className={ 'stepper-wrapper__text--link' }
                                       >{t('iconModalFour.textTwoLink')}
                                         <img className={ 'plain-list__external-link' }
                                              src={ externalLink }
                                              alt={t("mainPage.imageLinkExternal")} />
                                       </Link>&nbsp;
                                       {t('iconModalFour.textTwoPartTwo')}
                                     </Paragraph>
                                   </>
                                 }>
                  </IconModalItem>
                  <IconModalItem iconSource={ komercniBanka }
                                 className={ 'container-new__third-section--logo-icon' }
                                 alt={t("mainPage.sectionTwo.imagePartOneHeadingSmallTwoItemThreeAltText")}
                                 modalTitle={t('iconModalFour.titleOne')}
                                 modalButtonName={t('iconModalFour.buttonOne')}
                                 buttonLink={ 'https://www.bankid.cz/' }
                                 modalContent={
                                   <>
                                     <Headline type={3}
                                               className={ 'modal-heading' }>{t('iconModalFour.headingOne')}</Headline>
                                     <Paragraph className={ 'modal-text' }>{t('iconModalFour.textOne')}</Paragraph>
                                     <Headline type={3}
                                               className={ 'modal-heading' }>{t('iconModalFour.headingTwo')}</Headline>
                                     <List plain={true} inline={true} className={ 'list-plain' } >
                                       <ListItem>
                                         <img src={ airbank }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltOne")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ ceskaSporitelna }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltTwo")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ csobId }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltThree")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ fioBanka }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltSeven")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ komercniBanka }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltFour")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ moneta }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltFive")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ raiffeisenBank }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltSix")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ unicreditBank }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltEight")} />
                                       </ListItem>
                                       <ListItem>
                                          <img src={ creditas }
                                               alt={t("mainPage.sectionThree.itemOne.imageTextAltNine")}/>
                                      </ListItem>
                                      <ListItem>
                                        <img src={ mbank }
                                             alt={t("mainPage.sectionThree.itemOne.imageTextAltTen")}/>
                                      </ListItem>
                                     </List>
                                     <Headline type={3}
                                               className={ 'modal-heading' }>{t('iconModalFour.headingThree')}</Headline>
                                     <Paragraph className={ 'modal-text' }>
                                       {t('iconModalFour.textTwoPartOne')}&nbsp;
                                       <Link
                                         title={t('iconModalFour.textTwoLinkTitle')}
                                         href={ 'https://www.bankid.cz/' }
                                         target="_blank"
                                         className={ 'stepper-wrapper__text--link' }
                                       >{t('iconModalFour.textTwoLink')}
                                         <img className={ 'plain-list__external-link' }
                                              src={ externalLink }
                                              alt={t("mainPage.imageLinkExternal")} />
                                       </Link>&nbsp;
                                       {t('iconModalFour.textTwoPartTwo')}
                                     </Paragraph>
                                   </>
                                 }>
                  </IconModalItem>
                  <IconModalItem iconSource={ moneta }
                                 className={ 'container-new__third-section--logo-icon' }
                                 alt={t("mainPage.sectionTwo.imagePartOneHeadingSmallTwoItemSixAltText")}
                                 modalTitle={t('iconModalFour.titleOne')}
                                 modalButtonName={t('iconModalFour.buttonOne')}
                                 buttonLink={ 'https://www.bankid.cz/' }
                                 modalContent={
                                   <>
                                     <Headline type={3}
                                               className={ 'modal-heading' }>{t('iconModalFour.headingOne')}</Headline>
                                     <Paragraph className={ 'modal-text' }>{t('iconModalFour.textOne')}</Paragraph>
                                     <Headline type={3}
                                               className={ 'modal-heading' }>{t('iconModalFour.headingTwo')}</Headline>
                                     <List plain={true} inline={true} className={ 'list-plain' } >
                                       <ListItem>
                                         <img src={ airbank }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltOne")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ ceskaSporitelna }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltTwo")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ csobId }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltThree")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ fioBanka }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltSeven")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ komercniBanka }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltFour")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ moneta }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltFive")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ raiffeisenBank }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltSix")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ unicreditBank }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltEight")} />
                                       </ListItem>
                                       <ListItem>
                                          <img src={ creditas }
                                               alt={t("mainPage.sectionThree.itemOne.imageTextAltNine")}/>
                                      </ListItem>
                                      <ListItem>
                                        <img src={ mbank }
                                             alt={t("mainPage.sectionThree.itemOne.imageTextAltTen")}/>
                                      </ListItem>
                                     </List>
                                     <Headline type={3}
                                               className={ 'modal-heading' }>{t('iconModalFour.headingThree')}</Headline>
                                     <Paragraph className={ 'modal-text' }>
                                       {t('iconModalFour.textTwoPartOne')}&nbsp;
                                       <Link
                                         title={t('iconModalFour.textTwoLinkTitle')}
                                         href={ 'https://www.bankid.cz/' }
                                         target="_blank"
                                         className={ 'stepper-wrapper__text--link' }
                                       >{t('iconModalFour.textTwoLink')}
                                         <img className={ 'plain-list__external-link' }
                                              src={ externalLink }
                                              alt={t("mainPage.imageLinkExternal")} />
                                       </Link>&nbsp;
                                       {t('iconModalFour.textTwoPartTwo')}
                                     </Paragraph>
                                   </>
                                 }>
                  </IconModalItem>
                  <IconModalItem iconSource={ raiffeisenBank }
                                 className={ 'container-new__third-section--logo-icon' }
                                 alt={t("mainPage.sectionTwo.imagePartOneHeadingSmallTwoItemFiveAltText")}
                                 modalTitle={t('iconModalFour.titleOne')}
                                 modalButtonName={t('iconModalFour.buttonOne')}
                                 buttonLink={ 'https://www.bankid.cz/' }
                                 modalContent={
                                   <>
                                     <Headline type={3}
                                               className={ 'modal-heading' }>{t('iconModalFour.headingOne')}</Headline>
                                     <Paragraph className={ 'modal-text' }>{t('iconModalFour.textOne')}</Paragraph>
                                     <Headline type={3}
                                               className={ 'modal-heading' }>{t('iconModalFour.headingTwo')}</Headline>
                                     <List plain={true} inline={true} className={ 'list-plain' } >
                                       <ListItem>
                                         <img src={ airbank }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltOne")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ ceskaSporitelna }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltTwo")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ csobId }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltThree")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ fioBanka }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltSeven")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ komercniBanka }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltFour")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ moneta }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltFive")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ raiffeisenBank }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltSix")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ unicreditBank }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltEight")} />
                                       </ListItem>
                                       <ListItem>
                                          <img src={ creditas }
                                               alt={t("mainPage.sectionThree.itemOne.imageTextAltNine")}/>
                                      </ListItem>
                                      <ListItem>
                                        <img src={ mbank }
                                             alt={t("mainPage.sectionThree.itemOne.imageTextAltTen")}/>
                                      </ListItem>
                                     </List>
                                     <Headline type={3}
                                               className={ 'modal-heading' }>{t('iconModalFour.headingThree')}</Headline>
                                     <Paragraph className={ 'modal-text' }>
                                       {t('iconModalFour.textTwoPartOne')}&nbsp;
                                       <Link
                                         title={t('iconModalFour.textTwoLinkTitle')}
                                         href={ 'https://www.bankid.cz/' }
                                         target="_blank"
                                         className={ 'stepper-wrapper__text--link' }
                                       >{t('iconModalFour.textTwoLink')}
                                         <img className={ 'plain-list__external-link' }
                                              src={ externalLink }
                                              alt={t("mainPage.imageLinkExternal")} />
                                       </Link>&nbsp;
                                       {t('iconModalFour.textTwoPartTwo')}
                                     </Paragraph>
                                   </>
                                 }>
                  </IconModalItem>
                  <IconModalItem iconSource={ unicreditBank }
                                 className={ 'container-new__third-section--logo-icon' }
                                 alt={t("mainPage.sectionTwo.imagePartOneHeadingSmallTwoItemEightAltText")}
                                 modalTitle={t('iconModalFour.titleOne')}
                                 modalButtonName={t('iconModalFour.buttonOne')}
                                 buttonLink={ 'https://www.bankid.cz/' }
                                 modalContent={
                                   <>
                                     <Headline type={3}
                                               className={ 'modal-heading' }>{t('iconModalFour.headingOne')}</Headline>
                                     <Paragraph className={ 'modal-text' }>{t('iconModalFour.textOne')}</Paragraph>
                                     <Headline type={3}
                                               className={ 'modal-heading' }>{t('iconModalFour.headingTwo')}</Headline>
                                     <List plain={true} inline={true} className={ 'list-plain' } >
                                       <ListItem>
                                         <img src={ airbank }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltOne")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ ceskaSporitelna }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltTwo")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ csobId }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltThree")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ fioBanka }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltSeven")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ komercniBanka }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltFour")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ moneta }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltFive")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ raiffeisenBank }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltSix")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ unicreditBank }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltEight")} />
                                       </ListItem>
                                       <ListItem>
                                          <img src={ creditas }
                                               alt={t("mainPage.sectionThree.itemOne.imageTextAltNine")}/>
                                      </ListItem>
                                      <ListItem>
                                        <img src={ mbank }
                                             alt={t("mainPage.sectionThree.itemOne.imageTextAltTen")}/>
                                      </ListItem>
                                     </List>
                                     <Headline type={3}
                                               className={ 'modal-heading' }>{t('iconModalFour.headingThree')}</Headline>
                                     <Paragraph className={ 'modal-text' }>
                                       {t('iconModalFour.textTwoPartOne')}&nbsp;
                                       <Link
                                         title={t('iconModalFour.textTwoLinkTitle')}
                                         href={ 'https://www.bankid.cz/' }
                                         target="_blank"
                                         className={ 'stepper-wrapper__text--link' }
                                       >{t('iconModalFour.textTwoLink')}
                                         <img className={ 'plain-list__external-link' }
                                              src={ externalLink }
                                              alt={t("mainPage.imageLinkExternal")} />
                                       </Link>&nbsp;
                                       {t('iconModalFour.textTwoPartTwo')}
                                     </Paragraph>
                                   </>
                                 }>
                  </IconModalItem>
                  <IconModalItem iconSource={ creditas }
                                 className={ 'container-new__third-section--logo-icon' }
                                 alt={t("mainPage.sectionTwo.imagePartOneHeadingSmallTwoItemNineAltText")}
                                 modalTitle={t('iconModalFour.titleOne')}
                                 modalButtonName={t('iconModalFour.buttonOne')}
                                 buttonLink={ 'https://www.bankid.cz/' }
                                 modalContent={
                                   <>
                                     <Headline type={3}
                                               className={ 'modal-heading' }>{t('iconModalFour.headingOne')}</Headline>
                                     <Paragraph className={ 'modal-text' }>{t('iconModalFour.textOne')}</Paragraph>
                                     <Headline type={3}
                                               className={ 'modal-heading' }>{t('iconModalFour.headingTwo')}</Headline>
                                     <List plain={true} inline={true} className={ 'list-plain' } >
                                       <ListItem>
                                         <img src={ airbank }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltOne")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ ceskaSporitelna }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltTwo")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ csobId }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltThree")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ fioBanka }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltSeven")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ komercniBanka }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltFour")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ moneta }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltFive")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ raiffeisenBank }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltSix")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ unicreditBank }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltEight")} />
                                       </ListItem>
                                       <ListItem>
                                          <img src={ creditas }
                                               alt={t("mainPage.sectionThree.itemOne.imageTextAltNine")}/>
                                      </ListItem>
                                      <ListItem>
                                        <img src={ mbank }
                                             alt={t("mainPage.sectionThree.itemOne.imageTextAltTen")}/>
                                      </ListItem>
                                     </List>
                                     <Headline type={3}
                                               className={ 'modal-heading' }>{t('iconModalFour.headingThree')}</Headline>
                                     <Paragraph className={ 'modal-text' }>
                                       {t('iconModalFour.textTwoPartOne')}&nbsp;
                                       <Link
                                         title={t('iconModalFour.textTwoLinkTitle')}
                                         href={ 'https://www.bankid.cz/' }
                                         target="_blank"
                                         className={ 'stepper-wrapper__text--link' }
                                       >{t('iconModalFour.textTwoLink')}
                                         <img className={ 'plain-list__external-link' }
                                              src={ externalLink }
                                              alt={t("mainPage.imageLinkExternal")} />
                                       </Link>&nbsp;
                                       {t('iconModalFour.textTwoPartTwo')}
                                     </Paragraph>
                                   </>
                                 }>
                  </IconModalItem>
                  <IconModalItem iconSource={ mbank }
                                 className={ 'container-new__third-section--logo-icon' }
                                 alt={t("mainPage.sectionTwo.imagePartOneHeadingSmallTwoItemTenAltText")}
                                 modalTitle={t('iconModalFour.titleOne')}
                                 modalButtonName={t('iconModalFour.buttonOne')}
                                 buttonLink={ 'https://www.bankid.cz/' }
                                 modalContent={
                                   <>
                                     <Headline type={3}
                                               className={ 'modal-heading' }>{t('iconModalFour.headingOne')}</Headline>
                                     <Paragraph className={ 'modal-text' }>{t('iconModalFour.textOne')}</Paragraph>
                                     <Headline type={3}
                                               className={ 'modal-heading' }>{t('iconModalFour.headingTwo')}</Headline>
                                     <List plain={true} inline={true} className={ 'list-plain' } >
                                       <ListItem>
                                         <img src={ airbank }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltOne")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ ceskaSporitelna }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltTwo")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ csobId }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltThree")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ fioBanka }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltSeven")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ komercniBanka }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltFour")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ moneta }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltFive")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ raiffeisenBank }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltSix")} />
                                       </ListItem>
                                       <ListItem>
                                         <img src={ unicreditBank }
                                              alt={t("mainPage.sectionThree.itemOne.imageTextAltEight")} />
                                       </ListItem>
                                       <ListItem>
                                          <img src={ creditas }
                                               alt={t("mainPage.sectionThree.itemOne.imageTextAltNine")}/>
                                      </ListItem>
                                      <ListItem>
                                        <img src={ mbank }
                                             alt={t("mainPage.sectionThree.itemOne.imageTextAltTen")}/>
                                      </ListItem>
                                     </List>
                                     <Headline type={3}
                                               className={ 'modal-heading' }>{t('iconModalFour.headingThree')}</Headline>
                                     <Paragraph className={ 'modal-text' }>
                                       {t('iconModalFour.textTwoPartOne')}&nbsp;
                                       <Link
                                         title={t('iconModalFour.textTwoLinkTitle')}
                                         href={ 'https://www.bankid.cz/' }
                                         target="_blank"
                                         className={ 'stepper-wrapper__text--link' }
                                       >{t('iconModalFour.textTwoLink')}
                                         <img className={ 'plain-list__external-link' }
                                              src={ externalLink }
                                              alt={t("mainPage.imageLinkExternal")} />
                                       </Link>&nbsp;
                                       {t('iconModalFour.textTwoPartTwo')}
                                     </Paragraph>
                                   </>
                                 }>
                  </IconModalItem>
                </div>
              </div>
            }
            <div>
              {showMore &&
                <Headline type={3}
                          className={ 'container-new__third-section--heading-smaller u-mt--24' }>{t("mainPage.sectionTwo.imagePartOneHeadingSmallThree")}</Headline>
              }
              <div className={ 'container-new__third-section--logos' }>
                <IconModalItem iconSource={ mojeIDLogo }
                               className={ 'container-new__third-section--logo-icon' }
                               alt={t("mainPage.sectionTwo.imagePartOneHeadingSmallThreeItemOneAltText")}
                               classNameString={ 'container-new__third-section--logo-text' }
                               classNameItem={ 'container-new__third-section--logo-item' }
                               iconText={t("mainPage.sectionTwo.imagePartOneHeadingSmallThreeItemOneText")}
                               modalTitle={t('iconModalFive.titleOne')}
                               modalButtonName={t('iconModalFive.buttonOne')}
                               buttonLink={ 'https://www.mojeid.cz/cs/jak-na-to/' }
                               modalContent={
                                 <>
                                   <Headline type={3} className={ 'modal-heading' }>{t('iconModalFive.headingOne')}</Headline>
                                   <Paragraph className={'modal-text'}>{t('iconModalFive.textOne')}</Paragraph>
                                   <Headline type={3} className={ 'modal-heading' }>{t('iconModalFive.headingTwo')}</Headline>
                                   <Paragraph className={ 'modal-text' }>
                                     {t('iconModalFive.textTwoPartOne')}&nbsp;
                                     <Link
                                       title={t('iconModalFive.textTwoLinkTitle')}
                                       href={ 'https://www.mojeid.cz/cs/jak-na-to/' }
                                       target="_blank"
                                       className={ 'stepper-wrapper__text--link' }
                                     >{t('iconModalFive.textTwoLink')}
                                       <img className={ 'plain-list__external-link' }
                                            src={ externalLink }
                                            alt={t("mainPage.imageLinkExternal")} />
                                     </Link>&nbsp;
                                     {t('iconModalFive.textTwoPartTwo')}
                                   </Paragraph>
                                 </>
                               }>
                </IconModalItem>
                {showMore &&
                  <IconModalItem iconSource={ firstCertificateAuthority }
                                 className={ 'container-new__third-section--logo-icon' }
                                 alt={t("mainPage.sectionTwo.imagePartOneHeadingSmallThreeItemThreeAltText")}
                                 classNameString={ 'container-new__third-section--logo-text' }
                                 classNameItem={ 'container-new__third-section--logo-item' }
                                 iconText={t("mainPage.sectionTwo.imagePartOneHeadingSmallThreeItemThreeText")}
                                 modalTitle={t('iconModalSix.titleOne')}
                                 modalButtonName={t('iconModalSix.buttonOne')}
                                 buttonLink={ 'https://www.icaidentita.cz/ziskani-certifikatu' }
                                 modalContent={
                                   <>
                                     <Headline type={3}
                                               className={ 'modal-heading' }>{t('iconModalSix.headingOne')}</Headline>
                                     <Paragraph className={ 'modal-text' }>{t('iconModalSix.textOne')}</Paragraph>
                                     <Headline type={3}
                                               className={ 'modal-heading' }>{t('iconModalSix.headingTwo')}</Headline>
                                     <Paragraph className={ 'modal-text' }>
                                       {t('iconModalSix.textTwoPartOne')}&nbsp;
                                       <Link
                                         title={t('iconModalSix.textTwoLinkTitle')}
                                         href={ 'https://www.icaidentita.cz/ziskani-certifikatu' }
                                         target="_blank"
                                         className={ 'stepper-wrapper__text--link' }
                                       >{t('iconModalSix.textTwoLink')}
                                         <img className={ 'plain-list__external-link' }
                                              src={ externalLink }
                                              alt={t("mainPage.imageLinkExternal")} />
                                       </Link>&nbsp;
                                       {t('iconModalSix.textTwoPartTwo')}
                                     </Paragraph>
                                   </>
                                 }>
                  </IconModalItem>
                }
                {showMore &&
                  <IconModalItem iconSource={ unionLogo }
                                 className={ 'container-new__third-section--logo-icon' }
                                 alt={t("mainPage.sectionTwo.imagePartOneHeadingSmallThreeItemTwoAltText")}
                                 classNameString={ 'container-new__third-section--logo-text' }
                                 classNameItem={ 'container-new__third-section--logo-item' }
                                 iconText={t("mainPage.sectionTwo.imagePartOneHeadingSmallThreeItemTwoText")}
                                 modalTitle={t('iconModalSeven.titleOne')}
                                 noButton={true}
                                 modalContent={
                                   <>
                                     <Headline type={3} className={ 'modal-heading' }>{t('iconModalSeven.headingOne')}</Headline>
                                     <Paragraph className={'modal-text'}>{t('iconModalSeven.textOnePartOne')}&nbsp;
                                       <b>{t('iconModalSeven.textOnePartTwo')}</b> {t('iconModalSeven.textOnePartThree')}
                                     </Paragraph>
                                     <Headline type={3} className={ 'modal-heading' }>{t('iconModalSeven.headingTwo')}</Headline>
                                     <Paragraph className={ 'modal-text' }>
                                       {t('iconModalSeven.textTwo')}
                                     </Paragraph>
                                   </>
                                 }>
                  </IconModalItem>
                }
              </div>
            </div>
            <div className={ 'container-new__third-section--mobile-hidden-button' }>
              <Button color={ 'tertiary' }
                      size={ 'small' }
                      onClick={() => { setShowMore(!showMore); scrollToElement({ elem: 'icon-wrapper-one' }) }}>
                {showMore ? <>{t("mainPage.sectionThree.buttonOne")}</> : <>{t("mainPage.sectionThree.buttonTwo")}</>}
                {showMore ?
                  <img src={ arrowUp }
                       alt={t("mainPage.iconAltOne")}
                       className={ 'u-ml--14' } />
                  :
                  <img src={ arrowDown }
                       alt={t("mainPage.iconAltTwo")}
                       className={ 'u-ml--14' } />
                }
              </Button>
            </div>
          </div>
          <div className={ 'container-new__third-section--part-two' }>
            <ReactSVG src={ loginBigArrow }
                      alt={t("mainPage.sectionTwo.imagePartMiddleImageAltTextTwo")}
                      className={ 'container-new__third-section--login-arrow' } />
            <Headline type={3} className={ 'container-new__third-section--heading-arrow' }>{t("mainPage.sectionTwo.imagePartMiddleImageText")}</Headline>
          </div>
          <div className={ 'container-new__third-section--part-three u-bg-color--grey' } id={ 'icon-wrapper-two' } >
            <Headline type={2} className={ 'container-new__third-section--heading u-mb--24' }>{t("mainPage.sectionTwo.imagePartTwoHeadingNew")}</Headline>
            <IconModalItem svgSource={ erbGov }
                           className={ 'container-new__third-section--logo-icon' }
                           alt={t("mainPage.sectionTwo.imagePartTwoHeadingItemOneAltText")}
                           classNameString={ 'container-new__third-section--logo-name' }
                           classNameItem={ 'container-new__third-section--item' }
                           iconText={t("mainPage.sectionTwo.imagePartTwoHeadingItemOneText")}
                           modalTitle={t('iconModalEight.titleOne')}
                           modalButtonName={t('iconModalEight.buttonOne')}
                           buttonLink={ 'https://obcan.portal.gov.cz/prihlaseni' }
                           modalContent={
                             <>
                               <Paragraph className={ 'modal-text' }>{t('iconModalEight.textOne')}</Paragraph>
                               <Paragraph className={ 'modal-text' }>{t('iconModalEight.textTwo')}</Paragraph>
                             </>
                           }>
            </IconModalItem>
            <IconModalItem svgSource={ dataBox }
                           className={ 'container-new__third-section--logo-icon' }
                           alt={t("mainPage.sectionTwo.imagePartTwoHeadingItemThreeAltText")}
                           classNameString={ 'container-new__third-section--logo-name' }
                           classNameItem={ 'container-new__third-section--item' }
                           iconText={t("mainPage.sectionTwo.imagePartTwoHeadingItemThreeText")}
                           modalTitle={t('iconModalNine.titleOne')}
                           modalButtonName={t('iconModalNine.buttonOne')}
                           buttonLink={ 'https://info.mojedatovaschranka.cz/info/' }
                           modalContent={
                             <>
                               <Paragraph className={ 'modal-text' }>{t('iconModalNine.textOne')}</Paragraph>
                               <Paragraph className={ 'modal-text' }>
                                 <Link
                                   title={t('iconModalNine.textTwoLinkTitle')}
                                   href={ withLocale(Constants.ROUTE_PATHS.databox) }
                                   className={ 'stepper-wrapper__text--link' }
                                   >{t('iconModalNine.textTwoLink')}
                                 </Link>
                               </Paragraph>
                             </>
                           }>
            </IconModalItem>
            <IconModalItem svgSource={ cssz }
                           className={ 'container-new__third-section--logo-icon' }
                           alt={t("mainPage.sectionTwo.imagePartTwoHeadingItemNewFourAltText")}
                           classNameString={ 'container-new__third-section--logo-name' }
                           classNameItem={ 'container-new__third-section--item' }
                           iconText={t("mainPage.sectionTwo.imagePartTwoHeadingItemNewFourText")}
                           modalTitle={t('iconModalTen.titleOne')}
                           modalButtonName={t('iconModalTen.buttonOne')}
                           buttonLink={ 'https://eportal.cssz.cz/' }
                           modalContent={
                             <>
                               <Paragraph className={ 'modal-text' }>{t('iconModalTen.textOne')}</Paragraph>
                             </>
                           }>
            </IconModalItem>
            <IconModalItem svgSource={ portalMyTaxes }
                           className={ 'container-new__third-section--logo-icon' }
                           alt={t("mainPage.sectionTwo.imagePartTwoHeadingItemFourAltText")}
                           classNameString={ 'container-new__third-section--logo-name' }
                           classNameItem={ 'container-new__third-section--item' }
                           iconText={t("mainPage.sectionTwo.imagePartTwoHeadingItemFourText")}
                           modalTitle={t('iconModalEleven.titleOne')}
                           modalButtonName={t('iconModalEleven.buttonOne')}
                           buttonLink={ 'https://adisspr.mfcr.cz/pmd/home' }
                           modalContent={
                             <>
                               <Paragraph className={ 'modal-text' }>{t('iconModalEleven.textOne')}</Paragraph>
                             </>
                           }>
            </IconModalItem>
            {showMore &&
              <IconModalItem iconSource={ NIALogo }
                             className={ 'container-new__third-section--logo-icon' }
                             alt={t("mainPage.sectionTwo.imagePartTwoHeadingItemNewSixAltText")}
                             classNameString={ 'container-new__third-section--logo-name' }
                             classNameItem={ 'container-new__third-section--item' }
                             iconText={t("mainPage.sectionTwo.imagePartTwoHeadingItemNewSixText")}
                             modalTitle={t('iconModalTwelve.titleOne')}
                             modalButtonName={t('iconModalTwelve.buttonOne')}
                             buttonLink={ 'https://www.identitaobcana.cz/Home' }
                             modalContent={
                               <>
                                 <Paragraph className={ 'modal-text' }>{t('iconModalTwelve.textOnePartOne')}&nbsp;
                                   <b>{t('iconModalTwelve.textOnePartTwo') }</b> {t('iconModalTwelve.textOnePartThree')}
                                 </Paragraph>
                                 <Paragraph className={ 'modal-text' }>{t('iconModalTwelve.textTwoPartOne')}&nbsp;
                                   <b>{t('iconModalTwelve.textTwoPartTwo')}</b>
                                 </Paragraph>
                               </>
                             }>
              </IconModalItem>
            }
            {showMore &&
              <IconModalItem svgSource={ injection }
                             className={ 'container-new__third-section--logo-icon' }
                             alt={t("mainPage.sectionTwo.imagePartTwoHeadingItemTwoAltText")}
                             classNameString={ 'container-new__third-section--logo-name' }
                             classNameItem={ 'container-new__third-section--item' }
                             iconText={t("mainPage.sectionTwo.imagePartTwoHeadingItemNewTwoText")}
                             modalTitle={t('iconModalThirteen.titleOne')}
                             modalButtonName={t('iconModalThirteen.buttonOne') }
                             buttonLink={ 'https://ocko.uzis.cz/' }
                             modalContent={
                               <>
                                 <Paragraph className={ 'modal-text' }>{t('iconModalThirteen.textOne')}</Paragraph>
                               </>
                             }>
              </IconModalItem>
            }
            {showMore &&
              <IconModalItem svgSource={ pillsPrescription }
                             className={ 'container-new__third-section--logo-icon' }
                             alt={t("mainPage.sectionTwo.imagePartTwoHeadingItemFiveAltText")}
                             classNameString={ 'container-new__third-section--logo-name' }
                             classNameItem={ 'container-new__third-section--item' }
                             iconText={t("mainPage.sectionTwo.imagePartTwoHeadingItemFiveText")}
                             modalTitle={t('iconModalFourteen.titleOne')}
                             modalButtonName={t('iconModalFourteen.buttonOne')}
                             buttonLink={ 'https://pacient.erecept.sukl.cz/suklweb/Pacient/Account/Welcome?returnUrl=%2Fsuklweb%2FPacient%2FHome%2F' }
                             modalContent={
                               <>
                                 <Paragraph className={ 'modal-text' }>{t('iconModalFourteen.textOne')}</Paragraph>
                               </>
                             }>
              </IconModalItem>
            }
            {showMore &&
              <IconModalItem svgSource={ map }
                             className={ 'container-new__third-section--logo-icon' }
                             alt={t("iconModalFifteen.altOne")}
                             classNameString={ 'container-new__third-section--logo-name' }
                             classNameItem={ 'container-new__third-section--item' }
                             iconText={t("mainPage.sectionTwo.imagePartTwoHeadingItemSixText")}
                             modalTitle={t('iconModalFifteen.titleOne')}
                             modalButtonName={t('iconModalFifteen.buttonOne')}
                             buttonLink={ 'https://login.cuzk.cz/rozcestnik.do' }
                             modalContent={
                               <>
                                 <Paragraph className={ 'modal-text' }>{t('iconModalFifteen.textOne')}</Paragraph>
                               </>
                             }>
              </IconModalItem>
            }
            {showMore &&
              <IconModalItem svgSource={ portalForRegions }
                             className={ 'container-new__third-section--logo-icon' }
                             alt={t("mainPage.sectionTwo.imagePartTwoHeadingItemSevenAltText")}
                             classNameString={ 'container-new__third-section--logo-name' }
                             classNameItem={ 'container-new__third-section--item' }
                             iconText={t("mainPage.sectionTwo.imagePartTwoHeadingItemSevenText")}
                             modalTitle={t('iconModalSixteen.titleOne')}
                             noButton={true}
                             modalContent={
                               <>
                                 <Paragraph className={ 'modal-text' }>{t('iconModalSixteen.textOne')}</Paragraph>
                                 <Paragraph className={ 'modal-text' }>
                                   <Link
                                     title={t('iconModalSixteen.textTwoLinkOneTitle')}
                                     href={ 'https://obcan.portal.gov.cz/prihlaseni' }
                                     target="_blank"
                                     className={ 'stepper-wrapper__text--link' }
                                   >{t('iconModalSixteen.textTwoLinkOne')}
                                     <img className={ 'plain-list__external-link' }
                                          src={ externalLink }
                                          alt={t("mainPage.imageLinkExternal")}/>
                                   </Link>&nbsp;
                                   {t('iconModalSixteen.textTwoPartOne')}&nbsp;
                                   <Link
                                     title={t('iconModalSixteen.textTwoLinkTwoTitle')}
                                     href={ 'https://info.identitaobcana.cz/sep/' }
                                     target="_blank"
                                     className={ 'stepper-wrapper__text--link' }
                                   >{t('iconModalSixteen.textTwoLinkTwo')}
                                     <img className={ 'plain-list__external-link' }
                                          src={ externalLink }
                                          alt={t("mainPage.imageLinkExternal")}/>
                                   </Link>
                                 </Paragraph>
                               </>
                             }>
              </IconModalItem>
            }
            <div className={ 'container-new__third-section--mobile-hidden-button' }>
              <Button color={ 'tertiary' }
                      size={ 'small' }
                      onClick={() => { setShowMore(!showMore); scrollToElement({ elem: 'icon-wrapper-two' }) }}>
                {showMore ? <>{t("mainPage.sectionThree.buttonOne")}</> : <>{t("mainPage.sectionThree.buttonTwo")}</>}
                {showMore ?
                  <img src={ arrowUp }
                       alt={t("mainPage.iconAltOne")}
                       className={ 'u-ml--14' } />
                  :
                  <img src={ arrowDown }
                       alt={t("mainPage.iconAltTwo")}
                       className={ 'u-ml--14' } />
                }
              </Button>
            </div>
          </div>
        </div>
        <div className={ 'container-new__desktop-hidden-button' }>
          <Button color={ 'tertiary' }
                  size={ 'normal'}
                  onClick={() => { setShowMore(!showMore); scrollToElement({ elem: 'icon-wrapper-part' }) }}>
            {showMore ? <>{t("mainPage.sectionShowMore.buttonOne")}</> : <>{t("mainPage.sectionShowMore.buttonTwo")}</>}
            {showMore ?
              <img src={ arrowUp }
                   alt={t("mainPage.iconAltOne")}
                   className={ 'u-ml--14' } />
              :
              <img src={ arrowDown }
                   alt={t("mainPage.iconAltTwo")}
                   className={ 'u-ml--14' } />
            }
          </Button>
        </div>
      </Container>
    </section>
  )
}
