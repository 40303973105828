import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionItem,
  Alert,
  Headline,
  Link,
  List,
  ListItem,
  Paragraph,
  Stepper,
  StepDescription
} from '../../gov-components';

import bankId from '../../resources/icons/bank-id.svg';
import airbank from '../../resources/icons/airbank.png';
import ceskaSporitelna from '../../resources/icons/ceskasporitelna.png';
import csobId from '../../resources/icons/csob-id.png';
import komercniBanka from '../../resources/icons/komercnibanka.png';
import fioBanka from '../../resources/icons/fio-banka.png';
import moneta from '../../resources/icons/moneta.png';
import raiffeisenBank from '../../resources/icons/raiffeisenbank.png';
import unicreditBank from '../../resources/icons/unicredit.png';
import datovka from '../../resources/icons/datovka.svg';
import mojeID from '../../resources/icons/mojeid.svg';
import nothing from '../../resources/icons/have-nothing.svg';
import googleplay from '../../resources/icons/googleplay.svg';
import appstore from '../../resources/icons/appstore.svg';
import externalLink from '../../resources/icons/external.svg';
import creditas from '../../resources/icons/creditas.png';
import mbank from '../../resources/icons/mbank.png';

export const IdentityAccordionsNew = (props: {expandedId: number | undefined, onExpanded: (id: number | undefined ) => void}) => {
  const {expandedId, onExpanded} = props;

  const { t } = useTranslation();

  const stepperOneID = {
    steps: [
      {
        order: 1,
        title: t('stepperOneID.stepOneTitle'),
        images: ['/static/images/BankID-1A.png', '/static/images/BankID-1B.png'],
      },
      {
        order: 2,
        title: t('stepperOneID.stepTwoTitle'),
        description: t('stepperOneID.stepTwoDescription'),
        images: ['/static/images/BankID-2A.png']
      },
      {
        order: 3,
        title: t('stepperOneID.stepThreeTitle'),
      }
    ],
  }

  const stepperTwoID = {
    steps: [
      {
        order: 1,
        title: t('stepperTwoID.stepOneTitle'),
      },
      {
        order: 2,
        title: t('stepperTwoID.stepTwoTitle'),
        images: ['/static/images/DS-MEP-2.png'],
      },
      {
        order: 3,
        title: t('stepperTwoID.stepThreeTitle'),
        images: ['/static/images/DS-MEP-3A.png', '/static/images/DS-MEP-3B.png'],
      },
      {
        order: 4,
        title: t('stepperTwoID.stepFourTitle'),
        images: ['/static/images/DS-MEP-4A.png', '/static/images/DS-MEP-4B.png', '/static/images/DS-MEP-4C.png'],
      },
      {
        order: 5,
        title: t('stepperTwoID.stepFiveTitle'),
        images: ['/static/images/DS-MEP-5A.png', '/static/images/DS-MEP-5B.png', '/static/images/DS-MEP-5C.png'],
      },
    ],
  }

  const stepperThreeID = {
    steps: [
      {
        order: 1,
        title: t('stepperThreeID.stepOneTitle'),
        images: ['/static/images/DS-NIAID-1.png'],
      },
      {
        order: 2,
        title: t('stepperThreeID.stepTwoTitle'),
        images: ['/static/images/DS-NIAID-2A.png', '/static/images/DS-NIAID-2B.png'],
      },
      {
        order: 3,
        title: t('stepperThreeID.stepThreeTitle'),
        images: ['/static/images/DS-NIAID-3A.png', '/static/images/DS-NIAID-3B.png',
                 '/static/images/DS-NIAID-3C.png', '/static/images/DS-NIAID-3D.png',
                 '/static/images/DS-NIAID-3E.png'],
      },
      {
        order: 4,
        title: t('stepperThreeID.stepFourTitle'),
        images: ['/static/images/DS-NIAID-4A.png', '/static/images/DS-NIAID-4B.png'],
      }
    ],
  }

  const stepperFourID = {
    steps: [
      {
        order: 1,
        title: t('stepperFourID.stepOneTitle'),
      },
      {
        order: 2,
        title: t('stepperFourID.stepTwoTitle'),
      },
      {
        order: 3,
        title: t('stepperFourID.stepThreeTitle'),
      },
      {
        order: 4,
        title: t('stepperFourID.stepFourTitle'),
      },
    ],
  }

  const stepperFiveID = {
    steps: [
      {
        order: 1,
        title: t('stepperFiveID.stepOneTitle'),
      },
      {
        order: 2,
        title: t('stepperFiveID.stepTwoTitle'),
        imagesSmall: ['/static/images/CZP-MEP-2A.png', '/static/images/CZP-MEP-2B.png',
                      '/static/images/CZP-MEP-2C.png', '/static/images/CZP-MEP-2D.png',
                      '/static/images/CZP-MEP-2E.png'],
      },
      {
        order: 3,
        title: t('stepperFiveID.stepThreeTitle'),
        imagesSmall: ['/static/images/CZP-MEP-3.png'],
      },
      {
        order: 4,
        title: t('stepperFiveID.stepFourTitle'),
        imagesSmall: ['/static/images/CZP-MEP-4.png'],
      },
      {
        order: 5,
        title: t('stepperFiveID.stepFiveTitle'),
        images: ['/static/images/CZP-MEP-5A.png', '/static/images/CZP-MEP-5B.png',
                 '/static/images/CZP-MEP-5C.png'],
      },
    ],
  }

  const stepperSixID = {
    steps: [
      {
        order: 1,
        title: t('stepperSixID.stepOneTitle'),
        images: ['/static/images/CZP-NIAID-1A.png', '/static/images/CZP-NIAID-1B.png',
                 '/static/images/CZP-NIAID-1C.png', '/static/images/CZP-NIAID-1D.png'],
      },
      {
        order: 2,
        title: t('stepperSixID.stepTwoTitle'),
      },
      {
        order: 3,
        title: t('stepperSixID.stepThreeTitle'),
        images: ['/static/images/CZP-NIAID-3A.png', '/static/images/CZP-NIAID-3B.png'],
      },
    ],
  }

  return (
    <Accordion version={ 'new' } className={ 'gov-accordion--new--english-czech-version' } expandedItem={expandedId}
    onExpanded={onExpanded}>
      <AccordionItem
        title={t("mainPage.sectionThree.itemOne.title")}
        keyNumber={0}
        icon={<img src={ bankId }
                   alt={t("mainPage.sectionThree.itemOne.titleAltText")} />}
      >
        <Alert inContent={true}
               globalClosable={false}
               className={ 'u-mb--32' }>
          <Paragraph className={ 'gov-alert__content--bigger-text' }>
            {t('stepperOneID.stepOneAlertPartOne')} <b>{t('stepperOneID.stepOneAlertPartTwo')}</b>&nbsp;
            {t('stepperOneID.stepOneAlertPartThree')} <b>{t('stepperOneID.stepOneAlertPartFour')}</b>&nbsp;
            {t('stepperOneID.stepOneAlertPartFive')}
          </Paragraph>
        </Alert>
        <div className={ 'accordion-text-wrapper' }>
          <Stepper steps={ stepperOneID.steps } >
            <StepDescription key={1}>
              {t('stepperOneID.stepOneDescriptionPartOne')} <b>{t('stepperOneID.stepOneDescriptionPartTwo')}</b>
              {t('stepperOneID.stepOneDescriptionPartThree')}&nbsp;
              <Link
                title={t('stepperOneID.stepOneDescriptionLinkOneTitle')}
                href={ 'https://obcan.portal.gov.cz/prihlaseni' }
                target="_blank"
                className={ 'stepper-wrapper__text--link' }
              >{t('stepperOneID.stepOneDescriptionLinkOne')}
                <img className={ 'plain-list__external-link' }
                     src={ externalLink }
                     alt={t("mainPage.imageLinkExternal")} />
              </Link>&nbsp;
              {t('stepperOneID.stepOneDescriptionPartFour')}&nbsp;
              <Link
                title={t('stepperOneID.stepOneDescriptionLinkTwoTitle')}
                href={ 'https://info.mojedatovaschranka.cz/' }
                target="_blank"
                className={ 'stepper-wrapper__text--link' }
              >{t('stepperOneID.stepOneDescriptionLinkTwo')}
                <img className={ 'plain-list__external-link' }
                     src={ externalLink }
                     alt={t("mainPage.imageLinkExternal")} />
              </Link>
            </StepDescription>
            <StepDescription key={3}>
              {t('stepperOneID.stepThreeDescriptionPartOne')} <b>{t('stepperOneID.stepThreeDescriptionPartTwo')}</b>&nbsp;
              {t('stepperOneID.stepThreeDescriptionPartThree')}
            </StepDescription>
          </Stepper>
          <Headline type={3} className={ 'p-bigger u-mb--20 u-mt--4' }>{t("mainPage.sectionThree.itemOne.textSeven")}</Headline>
          <List plain={true} inline={true} className={ 'list-plain' } >
            <ListItem>
              <img src={ airbank } alt={t("mainPage.sectionThree.itemOne.imageTextAltOne")}/>
            </ListItem>
            <ListItem>
              <img src={ ceskaSporitelna } alt={t("mainPage.sectionThree.itemOne.imageTextAltTwo")}/>
            </ListItem>
            <ListItem>
              <img src={ csobId } alt={t("mainPage.sectionThree.itemOne.imageTextAltThree")}/>
            </ListItem>
            <ListItem>
              <img src={ fioBanka } alt={t("mainPage.sectionThree.itemOne.imageTextAltSeven")}/>
            </ListItem>
            <ListItem>
              <img src={ komercniBanka } alt={t("mainPage.sectionThree.itemOne.imageTextAltFour")}/>
            </ListItem>
            <ListItem>
              <img src={ moneta } alt={t("mainPage.sectionThree.itemOne.imageTextAltFive")}/>
            </ListItem>
            <ListItem>
              <img src={ raiffeisenBank } alt={t("mainPage.sectionThree.itemOne.imageTextAltSix")}/>
            </ListItem>
            <ListItem>
              <img src={ unicreditBank } alt={t("mainPage.sectionThree.itemOne.imageTextAltEight")}/>
            </ListItem>
            <ListItem>
              <img src={ creditas } alt={t("mainPage.sectionThree.itemOne.imageTextAltNine")}/>
            </ListItem>
            <ListItem>
              <img src={ mbank } alt={t("mainPage.sectionThree.itemOne.imageTextAltTen")}/>
            </ListItem>
          </List>
        </div>
      </AccordionItem>
      <AccordionItem
        title={t("mainPage.sectionThree.itemTwo.title")}
        keyNumber={1}
        icon={<img className={ 'plain-list__datovka' }
                   src={ datovka }
                   alt={t("mainPage.sectionThree.itemTwo.titleAltText")} />}
      >
        <Alert inContent={true}
               globalClosable={false}
               className={ 'u-mb--32' }>
          <Paragraph className={ 'gov-alert__content--bigger-text' }>
            <b>{t('stepperTwoID.stepOneAlertPartOne')}</b> {t('stepperTwoID.stepOneAlertPartTwo')}&nbsp;
            <b>{t('stepperTwoID.stepOneAlertPartThree')}</b>
          </Paragraph>
        </Alert>
        <div className={ 'accordion-text-wrapper' }>
          <Headline type={3} className={ 'p-bigger u-mb--8' }>{t("stepperTwoID.stepOneInfoTitle")}</Headline>
          <Paragraph className={ 'u-mb--24' }>{t('stepperTwoID.stepOneInfoPartOne')}&nbsp;
            <Link
              title={t('stepperTwoID.stepOneInfoLinkOneTitle')}
              href={ 'https://www.youtube.com/watch?v=m-k3-M6tymc' }
              target="_blank"
              className={ 'stepper-wrapper__text--link' }
            >{t('stepperTwoID.stepOneInfoLinkOne')}
              <img className={ 'plain-list__external-link' }
                   src={ externalLink }
                   alt={t("mainPage.imageLinkExternal")} />
            </Link>&nbsp;
            {t('stepperTwoID.stepOneInfoPartTwo')}&nbsp;
            <Link
              title={t('stepperTwoID.stepOneInfoLinkTwoTitle')}
              href={ 'https://www.youtube.com/watch?v=m-k3-M6tymc&t=76s' }
              target="_blank"
              className={ 'stepper-wrapper__text--link' }
            >{t('stepperTwoID.stepOneInfoLinkTwo')}
              <img className={ 'plain-list__external-link' }
                   src={ externalLink }
                   alt={t("mainPage.imageLinkExternal")} />
            </Link>
          </Paragraph>
          <Stepper steps={ stepperTwoID.steps }>
            <StepDescription key={1}>
              <span className={ 'stepper-wrapper' }>
                <span className={ 'stepper-wrapper__text' }>
                  {t('stepperTwoID.stepOneDescriptionPartOne')}&nbsp;
                  <Link
                    title={t("stepperTwoID.stepOneDescriptionLinkThreeTitle")}
                    href={t("https://play.google.com/store/apps/details?id=cz.mojedatovaschranka.mobilniklic&hl=cs&gl=US")}
                    target="_blank"
                    className={ 'stepper-wrapper__text--link' }
                  >{t('stepperTwoID.stepOneDescriptionLinkThree')}
                    <img className={ 'plain-list__external-link' }
                         src={ externalLink }
                         alt={t("mainPage.imageLinkExternal")} />
                  </Link>&nbsp;
                  {t('stepperTwoID.stepOneDescriptionPartTwo')}&nbsp;
                  <Link
                    title={t("stepperTwoID.stepOneDescripitonLinkFourTitle")}
                    href={t("https://apps.apple.com/cz/app/mobiln%C3%AD-kl%C3%AD%C4%8D-egovernmentu/id1466762017")}
                    target="_blank"
                    className={ 'stepper-wrapper__text--link' }
                  >{t('stepperTwoID.stepOneDescriptionLinkFour')}
                    <img className={ 'plain-list__external-link' }
                         src={ externalLink }
                         alt={t("mainPage.imageLinkExternal")} />
                  </Link>
                </span>
                <span className={ 'stepper-wrapper__icons' }>
                  <Link
                    title={t("mainPage.sectionThree.itemFour.imageOneLinkTitle")}
                    href={t("mainPage.sectionThree.itemFour.imageOneLinkHref")}
                    target="_blank"
                    className={ 'stepper-wrapper__icons--icon' }
                  >
                    <img src={ googleplay }
                         alt={t("mainPage.sectionThree.itemFour.imageOneAltText")} />
                  </Link>
                  <Link
                    title={t("mainPage.sectionThree.itemFour.imageTwoLinkTitle")}
                    href={t("mainPage.sectionThree.itemFour.imageTwoLinkHref")}
                    target="_blank"
                    className={ 'stepper-wrapper__icons--icon' }
                  >
                    <img src={ appstore }
                         alt={t("mainPage.sectionThree.itemFour.imageTwoAltText")}/>
                  </Link>
                </span>
              </span>
            </StepDescription>
            <StepDescription key={2}>
              <Link
                title={t("stepperTwoID.stepTwoDescriptionLinkOneTitle")}
                href={t("https://info.mojedatovaschranka.cz/")}
                target="_blank"
                className={ 'stepper-wrapper__text--link' }
              >{t('stepperTwoID.stepTwoDescriptionLinkOne')}
                <img className={ 'plain-list__external-link' }
                     src={ externalLink }
                     alt={t("mainPage.imageLinkExternal")} />
              </Link>
            </StepDescription>
            <StepDescription key={3}>
              {t('stepperTwoID.stepThreeDescriptionPartOne')} <b>{t('stepperTwoID.stepThreeDescriptionPartTwo')}</b>&nbsp;
              {t('stepperTwoID.stepThreeDescriptionPartThree')} <b>{t('stepperTwoID.stepThreeDescriptionPartFour')}</b>&nbsp;
              {t('stepperTwoID.stepThreeDescriptionPartFive')} <b>{t('stepperTwoID.stepThreeDescriptionPartSix')}</b>&nbsp;
              {t('stepperTwoID.stepThreeDescriptionPartSeven')} <b>{t('stepperTwoID.stepThreeDescriptionPartEight')}</b>&nbsp;
              {t('stepperTwoID.stepThreeDescriptionPartNine')} <b>{t('stepperTwoID.stepThreeDescriptionPartTen')}</b>
            </StepDescription>
            <StepDescription key={4}>
              {t('stepperTwoID.stepFourDescriptionPartOne')} <b>{t('stepperTwoID.stepFourDescriptionPartTwo')}</b>&nbsp;
              {t('stepperTwoID.stepFourDescriptionPartThree')} <b>{t('stepperTwoID.stepFourDescriptionPartFour')}</b>&nbsp;
              {t('stepperTwoID.stepFourDescriptionPartFive')}
            </StepDescription>
            <StepDescription key={5}>
              {t('stepperTwoID.stepFiveDescriptionPartOne')} <b>{t('stepperTwoID.stepFiveDescriptionPartTwo')}</b>&nbsp;
              {t('stepperTwoID.stepFiveDescriptionPartThree')} <b>{t('stepperTwoID.stepFiveDescriptionPartFour')}</b>&nbsp;
              {t('stepperTwoID.stepFiveDescriptionPartFive')} <b>{t('stepperTwoID.stepFiveDescriptionPartSix')}</b>
            </StepDescription>
          </Stepper>
          <Headline type={3} className={ 'p-bigger u-mb--24 u-mt--34' }>{t('stepperThreeID.stepOneInfoPartOne')}</Headline>
          <Stepper steps={ stepperThreeID.steps }>
            <StepDescription key={1}>
              <Link
                title={t("stepperTwoID.stepTwoDescriptionLinkOneTitle")}
                href={t("https://info.mojedatovaschranka.cz/")}
                target="_blank"
                className={ 'stepper-wrapper__text--link' }
              >{t('stepperTwoID.stepTwoDescriptionLinkOne')}
                <img className={ 'plain-list__external-link' }
                     src={ externalLink }
                     alt={t("mainPage.imageLinkExternal")} />
              </Link>
            </StepDescription>
            <StepDescription key={2}>
              {t('stepperTwoID.stepThreeDescriptionPartOne')} <b>{t('stepperTwoID.stepThreeDescriptionPartTwo')}</b>&nbsp;
              {t('stepperTwoID.stepThreeDescriptionPartThree')} <b>{t('stepperTwoID.stepThreeDescriptionPartFour')}</b>&nbsp;
              {t('stepperTwoID.stepThreeDescriptionPartFive')} <b>{t('stepperTwoID.stepThreeDescriptionPartSix')}</b>&nbsp;
              {t('stepperTwoID.stepThreeDescriptionPartSeven')} <b>{t('stepperTwoID.stepThreeDescriptionPartEight')}</b>&nbsp;
              {t('stepperTwoID.stepThreeDescriptionPartNine')} <b>{t('stepperTwoID.stepThreeDescriptionPartTen')}</b>
            </StepDescription>
            <StepDescription key={3}>
              {t('stepperThreeID.stepThreeDescriptionPartOne')} <b>{t('stepperThreeID.stepThreeDescriptionPartTwo')}</b>&nbsp;
              {t('stepperThreeID.stepThreeDescriptionPartThree')} <b>{t('stepperThreeID.stepThreeDescriptionPartFour')}</b>&nbsp;
              {t('stepperThreeID.stepThreeDescriptionPartFive')} <b>{t('stepperThreeID.stepThreeDescriptionPartSix')}</b>
            </StepDescription>
            <StepDescription key={4}>
              {t('stepperThreeID.stepFourDescriptionPartOne')} <b>{t('stepperThreeID.stepFourDescriptionPartTwo')}</b>&nbsp;
              {t('stepperThreeID.stepFourDescriptionPartThree')} <b>{t('stepperThreeID.stepFourDescriptionPartFour')}</b>&nbsp;
              {t('stepperThreeID.stepFourDescriptionPartFive')} <b>{t('stepperThreeID.stepFourDescriptionPartSix')}</b>&nbsp;
              {t('stepperThreeID.stepFourDescriptionPartSeven')} <b>{t('stepperThreeID.stepFourDescriptionPartEight')}</b>&nbsp;
              {t('stepperThreeID.stepFourDescriptionPartNine')}
            </StepDescription>
          </Stepper>
        </div>
      </AccordionItem>
      <AccordionItem
        title={t("mainPage.sectionThree.itemThree.titleTwo")}
        keyNumber={2}
        icon={<img className={ 'plain-list__moje-id' }
                   src={ mojeID }
                   alt={t("mainPage.sectionThree.itemThree.titleAltText")} />}
      >
        <Alert inContent={true}
               globalClosable={false}
               className={ 'u-mb--32' }>
          <Paragraph className={ 'gov-alert__content--bigger-text' }>
            {t('stepperFourID.stepOneAlertPartOne')}
          </Paragraph>
        </Alert>
        <div className={ 'accordion-text-wrapper' }>
          <Paragraph className={ 'u-mb--24' }>
            {t('stepperFourID.stepOneInfoPartOne')}&nbsp;
            <Link
              title={t('stepperFourID.stepOneInfoLinkOneTitle')}
              href={ 'https://www.mojeid.cz/cs/jak-na-to/' }
              target="_blank"
              className={ 'stepper-wrapper__text--link' }
            >{t('stepperFourID.stepOneInfoLinkOne')}
              <img className={ 'plain-list__external-link' }
                   src={ externalLink }
                   alt={t("mainPage.imageLinkExternal")} />
            </Link>
          </Paragraph>
          <Stepper steps={ stepperFourID.steps }>
            <StepDescription key={1}>
              <Link
                title={t("stepperFourID.stepOneDescriptionLinkTitle")}
                href={ "https://www.mojeid.cz/cs/zalozit-ucet/" }
                target="_blank"
                className={ 'stepper-wrapper__text--link' }
              >{t('stepperFourID.stepOneDescriptionLinkOne')}
                <img className={ 'plain-list__external-link' }
                     src={ externalLink }
                     alt={t("mainPage.imageLinkExternal")} />
              </Link>&nbsp;
              {t('stepperFourID.stepOneDescriptionPartOne')} <b>{t('stepperFourID.stepOneDescriptionPartTwo')}</b>&nbsp;
              {t('stepperFourID.stepOneDescriptionPartThree')}
            </StepDescription>
            <StepDescription key={2}>
              {t('stepperFourID.stepTwoDescriptionPartOne')} <b>{t('stepperFourID.stepTwoDescriptionPartTwo')}</b>&nbsp;
              {t('stepperFourID.stepTwoDescriptionPartThree')}
            </StepDescription>
            <StepDescription key={3}>
              {t('stepperFourID.stepThreeDescriptionPartOne')} <b>{t('stepperFourID.stepThreeDescriptionPartTwo')}</b>&nbsp;
              {t('stepperFourID.stepThreeDescriptionPartThree')}&nbsp;
              <Link
                title={t("stepperFourID.stepThreeDescripitonLinkOneTitle")}
                href={t("https://www.czechpoint.cz/wwwstats/f?p=100%3A16")}
                target="_blank"
                className={ 'stepper-wrapper__text--link' }
              >{t('stepperFourID.stepThreeDescripitonLinkOne')}
                <img className={ 'plain-list__external-link' }
                     src={ externalLink }
                     alt={t("mainPage.imageLinkExternal")} />
              </Link>&nbsp;
              {t('stepperFourID.stepThreeDescriptionPartFour')}
            </StepDescription>
            <StepDescription key={4}>
              {t('stepperFourID.stepFourDescriptionPartOne')} <b>{t('stepperFourID.stepFourDescriptionPartTwo')}</b>&nbsp;
              {t('stepperFourID.stepFourDescriptionPartThree')} <b>{t('stepperFourID.stepFourDescriptionPartFour')}</b>
            </StepDescription>
          </Stepper>
        </div>
      </AccordionItem>
      <AccordionItem
        title={t("mainPage.sectionThree.itemFour.title")}
        icon={<img src={ nothing }
                   alt={t("mainPage.sectionThree.itemFour.titleAltText")}/>}
        keyNumber={3}
      >
        <Alert inContent={true}
               globalClosable={false}
               className={ 'u-mb--32' }>
          <Paragraph className={ 'gov-alert__content--bigger-text' }>
            {t('stepperFiveID.stepOneAlertPartOne')}
          </Paragraph>
        </Alert>
        <div className={ 'accordion-text-wrapper' }>
          <Headline type={3} className={ 'p-bigger u-mb--8' }>{t('stepperFiveID.stepOneInfoTitle')}</Headline>
          <Paragraph className={ 'u-mb--24' }>
            {t('stepperFiveID.stepOneInfoPartOne')}&nbsp;
            <Link
              title={t('stepperFiveID.stepOneInfoLinkOneTitle')}
              href={ 'https://www.youtube.com/watch?v=m-k3-M6tymc' }
              target="_blank"
              className={ 'stepper-wrapper__text--link' }
            >{t('stepperFiveID.stepOneInfoLinkOne')}
              <img className={ 'plain-list__external-link' }
                   src={ externalLink }
                   alt={t("mainPage.imageLinkExternal")} />
            </Link>
          </Paragraph>
          <Stepper steps={ stepperFiveID.steps }>
            <StepDescription key={1}>
              <span className={ 'stepper-wrapper' }>
                <span className={ 'stepper-wrapper__text' }>
                  {t('stepperTwoID.stepOneDescriptionPartOne')}&nbsp;
                  <Link
                    title={t("stepperTwoID.stepOneDescriptionLinkThreeTitle")}
                    href={t("https://play.google.com/store/apps/details?id=cz.mojedatovaschranka.mobilniklic&hl=cs&gl=US")}
                    target="_blank"
                    className={ 'stepper-wrapper__text--link' }
                  >{t('stepperTwoID.stepOneDescriptionLinkThree')}
                    <img className={ 'plain-list__external-link' }
                         src={ externalLink }
                         alt={t("mainPage.imageLinkExternal")} />
                  </Link>&nbsp;
                  {t('stepperTwoID.stepOneDescriptionPartTwo')}&nbsp;
                  <Link
                    title={t("stepperTwoID.stepOneDescripitonLinkFourTitle")}
                    href={t("https://apps.apple.com/cz/app/mobiln%C3%AD-kl%C3%AD%C4%8D-egovernmentu/id1466762017")}
                    target="_blank"
                    className={ 'stepper-wrapper__text--link' }
                  >{t('stepperTwoID.stepOneDescriptionLinkFour')}
                    <img className={ 'plain-list__external-link' }
                         src={ externalLink }
                         alt={t("mainPage.imageLinkExternal")} />
                  </Link>
                </span>
                <span className={ 'stepper-wrapper__icons' }>
                  <Link
                    title={t("mainPage.sectionThree.itemFour.imageOneLinkTitle")}
                    href={t("mainPage.sectionThree.itemFour.imageOneLinkHref")}
                    target="_blank"
                    className={ 'stepper-wrapper__icons--icon' }
                  >
                    <img src={ googleplay }
                         alt={t("mainPage.sectionThree.itemFour.imageOneAltText")} />
                  </Link>
                  <Link
                    title={t("mainPage.sectionThree.itemFour.imageTwoLinkTitle")}
                    href={t("mainPage.sectionThree.itemFour.imageTwoLinkHref")}
                    target="_blank"
                    className={ 'stepper-wrapper__icons--icon' }
                  >
                    <img src={ appstore }
                         alt={t("mainPage.sectionThree.itemFour.imageTwoAltText")}/>
                  </Link>
                </span>
              </span>
            </StepDescription>
            <StepDescription key={2}>
              {t('stepperFiveID.stepTwoDescriptionPartOne')} <b>{t('stepperFiveID.stepTwoDescriptionPartTwo')}</b>&nbsp;
              {t('stepperFiveID.stepTwoDescriptionPartThree')} <b>{t('stepperFiveID.stepTwoDescriptionPartFour')}</b>&nbsp;
              {t('stepperFiveID.stepTwoDescriptionPartFive')} <b>{t('stepperFiveID.stepTwoDescriptionPartSix')}</b>&nbsp;
              {t('stepperFiveID.stepTwoDescriptionPartSeven')} <b>{t('stepperFiveID.stepTwoDescriptionPartEight')}</b>&nbsp;
              {t('stepperFiveID.stepTwoDescriptionPartNine')} <b>{t('stepperFiveID.stepTwoDescriptionPartTen')}</b>&nbsp;
              {t('stepperFiveID.stepTwoDescriptionPartEleven')} <b>{t('stepperFiveID.stepTwoDescriptionPartTwelve')}</b>&nbsp;
              {t('stepperFiveID.stepTwoDescriptionPartThirteen')} <b>{t('stepperFiveID.stepTwoDescriptionPartFourteen')}</b>
            </StepDescription>
            <StepDescription key={3}>
              <Link
                title={t("stepperFiveID.stepThreeDescripitonLinkOneTitle")}
                href={t("https://www.czechpoint.cz/wwwstats/f?p=100%3A16")}
                target="_blank"
                className={ 'stepper-wrapper__text--link' }
              >{t('stepperFiveID.stepThreeDescripitonLinkOne')}
                <img className={ 'plain-list__external-link' }
                     src={ externalLink }
                     alt={t("mainPage.imageLinkExternal")} />
              </Link>&nbsp;
              {t('stepperFiveID.stepThreeDescriptionPartOne')} <b>{t('stepperFiveID.stepThreeDescriptionPartTwo')}</b>&nbsp;
              {t('stepperFiveID.stepThreeDescriptionPartThree')} <b>{t('stepperFiveID.stepThreeDescriptionPartFour')}</b>&nbsp;
              {t('stepperFiveID.stepThreeDescriptionPartFive')} <b>{t('stepperFiveID.stepThreeDescriptionPartSix')}</b>&nbsp;
              {t('stepperFiveID.stepThreeDescriptionPartSeven')} <b>{t('stepperFiveID.stepThreeDescriptionPartEight')}</b>&nbsp;
              {t('stepperFiveID.stepThreeDescriptionPartNine')} <b>{t('stepperFiveID.stepThreeDescriptionPartTen')}</b>&nbsp;
              {t('stepperFiveID.stepThreeDescriptionPartEleven')} <b>{t('stepperFiveID.stepThreeDescriptionPartTwelve')}</b>&nbsp;
              {t('stepperFiveID.stepThreeDescriptionPartThirteen')}
            </StepDescription>
            <StepDescription key={4}>
              {t('stepperFiveID.stepFourDescriptionPartOne')} <b>{t('stepperFiveID.stepFourDescriptionPartTwo')}</b>&nbsp;
            </StepDescription>
            <StepDescription key={5}>
              {t('stepperTwoID.stepFiveDescriptionPartOne')} <b>{t('stepperTwoID.stepFiveDescriptionPartTwo')}</b>&nbsp;
              {t('stepperTwoID.stepFiveDescriptionPartThree')} <b>{t('stepperTwoID.stepFiveDescriptionPartFour')}</b>&nbsp;
              {t('stepperTwoID.stepFiveDescriptionPartFive')} <b>{t('stepperTwoID.stepFiveDescriptionPartSix')}</b>
            </StepDescription>
          </Stepper>
          <Headline type={3} className={ 'p-bigger u-mb--24 u-mt--34' }>{t('stepperSixID.stepOneInfoTitle')}</Headline>
          <Stepper steps={ stepperSixID.steps }>
            <StepDescription key={1}>
              <Link
                title={t("stepperSixID.stepOneDescripitonLinkOneTitle")}
                href={t("https://www.identitaobcana.cz/ProfileRegistration")}
                target="_blank"
                className={ 'stepper-wrapper__text--link' }
              >{t('stepperSixID.stepOneDescripitonLinkOne')}
                <img className={ 'plain-list__external-link' }
                     src={ externalLink }
                     alt={t("mainPage.imageLinkExternal")} />
              </Link>&nbsp;
              {t('stepperSixID.stepOneDescriptionPartOne')} <b>{t('stepperSixID.stepOneDescriptionPartTwo')}</b>
            </StepDescription>
            <StepDescription key={2}>
              <Link
                title={t("stepperSixID.stepTwoDescripitonLinkOneTitle")}
                href={t("https://www.czechpoint.cz/wwwstats/f?p=100%3A16")}
                target="_blank"
                className={ 'stepper-wrapper__text--link' }
              >{t('stepperSixID.stepTwoDescripitonLinkOne')}
                <img className={ 'plain-list__external-link' }
                     src={ externalLink }
                     alt={t("mainPage.imageLinkExternal")} />
              </Link>&nbsp;
              {t('stepperSixID.stepTwoDescriptionPartOne')} <b>{t('stepperSixID.stepTwoDescriptionPartTwo')}</b>&nbsp;
              {t('stepperSixID.stepTwoDescriptionPartThree')} <b>{t('stepperSixID.stepTwoDescriptionPartFour')}</b>&nbsp;
              {t('stepperSixID.stepTwoDescriptionPartFive')} <b>{t('stepperSixID.stepTwoDescriptionPartSix')}</b>&nbsp;
              {t('stepperSixID.stepTwoDescriptionPartSeven')} <b>{t('stepperSixID.stepTwoDescriptionPartEight')}</b>&nbsp;
              {t('stepperSixID.stepTwoDescriptionPartNine')} <b>{t('stepperSixID.stepTwoDescriptionPartTen')}</b>
            </StepDescription>
            <StepDescription key={3}>
              {t('stepperThreeID.stepFourDescriptionPartOne')} <b>{t('stepperThreeID.stepFourDescriptionPartTwo')}</b>&nbsp;
              {t('stepperThreeID.stepFourDescriptionPartThree')} <b>{t('stepperThreeID.stepFourDescriptionPartFour')}</b>&nbsp;
              {t('stepperThreeID.stepFourDescriptionPartFive')} <b>{t('stepperThreeID.stepFourDescriptionPartSix')}</b>&nbsp;
              {t('stepperThreeID.stepFourDescriptionPartSeven')} <b>{t('stepperThreeID.stepFourDescriptionPartEight')}</b>&nbsp;
              {t('stepperThreeID.stepFourDescriptionPartNine')}
            </StepDescription>
          </Stepper>
        </div>
      </AccordionItem>
    </Accordion>
  )
}
